import React from 'react'

import { Link } from 'gatsby'

import logo from "../images/logo.png"


const Header = () => {
    return (
        <header>
            <div className="header-content page-width">
                <h1><strong>North-NorthWest</strong> - <span>a web development studio</span></h1>
                <Link to="/" className='logo'><img src={logo} alt="Logo" /></Link>
                <nav>
                    <ul>
                        <li><Link to="/" activeClassName="active">Home</Link></li>
                        <li><Link to="/about" activeClassName="active">About</Link></li>
                        <li><Link to="/services" activeClassName="active">Services</Link></li>
                        <li><Link to="/contact" activeClassName="active">Contact</Link></li>
                        <li><a href="https://blog.north-northwest.com/">Blog</a></li>
                    </ul>

                    <ul className="language-selector">
                    <li><Link to="/" className='language-selected'>EN</Link></li>
                    <li><Link to="/index-croatian">HR</Link></li>
                </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header