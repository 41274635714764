import React from 'react'

import Header from '../components/header'

import Footer from '../components/footer'

import '../css/style.css'
import '../css/header.css'
import '../css/footer.css'
import '../css/pitch.css'


const Layout = (props) => {
    return (
        <div>
        <Header />
            {props.children}
        <Footer />
        </div>
    )
}

export default Layout