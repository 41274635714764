import React from 'react'

import { Link } from 'gatsby'
import logo from "../images/logo.png"

const Footer = () => {
    return (
        <footer className="page-width footer-wrap">
            <div className="contact-info">
                <Link to="/"><img src={logo} alt="Logo" /></Link>
                <div>
                    <h5>Have a project in mind?</h5>
                    <p><Link to="/contact">Drop me a line.</Link></p>
                </div>
            </div>
            <div class="footer-nav-wrap">
                <ul className='footer-nav'>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><a href="https://blog.north-northwest.com/">Blog</a></li>
                </ul>
                <p>&copy; Sjever-sjeverozapad, 2022., <strong>est. in Bristol, 2009</strong></p>
            </div>
        </footer>
    )
}

export default Footer