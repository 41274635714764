import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import { SEO } from "../components/seo"

import amarte from "../images/amarte-1.jpg"
import amarteFull from "../images/amarte-2.jpg"

const amarteSite = () => {
  return (
    <div>
         <Layout>
              <div className="container page-width-medium center projects">
                 
                  <img src={amarte} alt="Ecomm Jobs" />
                  <h2 class="mt50">Wonder Cream </h2>
                  <h3 className='subtitle'>A Product Launch Landing Page</h3>
                  <small class="center">Year: 2021</small>
                  <p className='mt20'>The design brief asked for a simple and clean ecommerce page that will emphasize the products soundness.</p>
                  <p>The tool used for the job was Adobe XD.</p>
                 
              </div>       

              <img className='mt50 project-img' src={amarteFull} alt="Wonder Cream" />

         </Layout>
    </div>
  )
  }

  export default amarteSite
  export const Head = () => (
    <SEO />
  )
  
  